import React from 'react';
import './Header.css'; // Importar el archivo CSS para estilos

function Header() {
  return (
    <header id="home"  className="header">
      <h1>Manuel Alba</h1>
      <p>Alias el malba</p>
    </header>
  );
}

export default Header;